import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { POPUP_TEXT } from "../../constants";

function MainPopup() {
  let context = useContext(MainContext);

  if (!context.showMainPopup) {
    return null;
  }

  return (
    <div className="synapzai-main-popup-container">
      <div className="synapzai-main-popup-wrapper">
        <div className="synapzai-popup-nib-inhibitor"></div>
        <div className="synapzai-main-popup">
          <p>{POPUP_TEXT}</p>
        </div>
        <div onClick={context.closePopup} className="synapzai-main-popup-close">
          <FontAwesomeIcon className="synapzai-main-popup-close-icon" icon={faClose} color="white" />
        </div>
      </div>
    </div>
  );
}

export default MainPopup;