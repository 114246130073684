import React, { Component } from "react";
import { INVOKE_SCRIPT_ID, MOBILE_BREAKPOINT_WIDTH, PRODUCT_ITEM_WIDTH_MOBILE, PRODUCT_ITEM_WIDTH_STANDARD, ROLE_ASSISTANT, ROLE_ENTITIES, ROLE_RESET, ROLE_USER } from "../../constants";
import MessageBubble from "./MessageBubble";
import './chatlog.css';
import ProductItem from "./ProductItem";
import PillContainer from "../Pills/PillContainer";
import { MainContext } from "../../context/MainContext";
import incomingAudio from '../../assets/audio/incoming.mp3';
import outgoingAudio from '../../assets/audio/outgoing.mp3';
import FeedbackPanel from "../Core/FeedbackPanel";

class ChatLog extends Component {

  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.chatLogElement = React.createRef();
  }

  componentDidMount() {
    this.chatLogElement.current.scrollTop = this.chatLogElement.current.scrollHeight;
  }

  componentDidUpdate(prevProps) {
    // scroll to the bottom of the chat log if there is a new message detected
    if (this.props.chatHistory.length > prevProps.chatHistory.length) {
      this.chatLogElement.current.scrollTop = this.chatLogElement.current.scrollHeight;
    }
  }

  getLastLogEntry() {
    const { chatHistory } = this.props;

    if (chatHistory.length > 0) {
      return chatHistory[chatHistory.length - 1];
    }

    return null;
  }

  /**
   * Checks the last log entry for a role, if the roles match return true
   * @param {String} roleToMatch 
   * @returns {Bool}
   */
  lastLogEntryHasRole(roleToMatch) {
    let lastEntry = this.getLastLogEntry();

    if (lastEntry) {
      return lastEntry.role == roleToMatch;
    }

    return false;
  }

  onScroll() {
    // hide keyboard on scroll for mobile devices
    if (window.innerWidth <= MOBILE_BREAKPOINT_WIDTH) {
      let editor = document.querySelector('.synapzai-query-input-editor');
      if (editor) {
        setTimeout(() => {
          editor.blur();
        })
      }
    }
  }

  // gets the style for the grid that is applied to the product list
  getGridTemplateColumns(entities) {
    if (window.innerWidth <= MOBILE_BREAKPOINT_WIDTH) {
      return `${PRODUCT_ITEM_WIDTH_MOBILE}px `.repeat(entities.length).trim();
    }

    let chatLogWidth = 400;
    let chatLogEl = document.querySelector('.synapzai-chatlog');

    if (chatLogEl) {
      chatLogWidth = chatLogEl.offsetWidth;
    }

    let divisions = Math.round(chatLogWidth / PRODUCT_ITEM_WIDTH_STANDARD);
    let portion = Math.round(100 / divisions);
    return `${portion}% `.repeat(divisions).trim();
  }

  // should the reset chat button be fixed to the bottom of the chat log
  isFixedResetChatButton() {
    const { chatHistory } = this.context;

    // fix reset chat to the bottom if there is an initial message and a user message
    if (this.context.getInitialMessage() && chatHistory.length == 2) {
      return true;
    }

    if (chatHistory.length == 1) {
      return true;
    }

    if (chatHistory.length == 2 && chatHistory[0].role == ROLE_RESET) {
      return true;
    }

    return false;
  }

  // condition whether we can show the 'reset chat' button or not
  canShowResetChat() {
    const { chatHistory } = this.context;

    // don't show reset chat if its just the initial message
    if (this.context.getInitialMessage()) {
      return !(chatHistory.length <= 1);
    }

    if (chatHistory.length == 1 && chatHistory[0].role == ROLE_RESET) {
      return false;
    }

    return true;
  }

  render() {
    const { chatHistory } = this.props;

    // we hide this component if there are less than 2 messages
    var isHidden = chatHistory.length < 1;

    const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
    const initialMessage = scriptTag.getAttribute('data-initial-message');

    if (initialMessage) {
      isHidden = false;
    }
  
    return (
      <div 
        ref={this.chatLogElement}
        onScroll={this.onScroll}
        style={{ height: this.props.chatLogHeight + 'px' }} 
        className={"synapzai-chatlog" + (isHidden ? ' synapzai-chatlog-hidden' : '')}
      >
        {chatHistory.map((item, index) => 
          item.role != ROLE_ENTITIES ?
          <MessageBubble key={index} message={item.content} role={item.role} />
          :
          <div key={index} style={{ gridTemplateColumns: this.getGridTemplateColumns(item.content) }} className="synapzai-chatlog-entities">
            {item.content.map((entity, index) => (
              <ProductItem topMatch={index == 0} key={index} product={entity} />
            ))}
          </div>
        )}
        {
          this.context.sendingQuery &&
          <MessageBubble isTypingBubble role={ROLE_ASSISTANT} />
        }
        {this.lastLogEntryHasRole(ROLE_ENTITIES) &&
          <PillContainer />
        }
        {this.context.widgetOpen && this.canShowResetChat() &&
          <div
            className={"synapzai-chat-reset" + (this.isFixedResetChatButton() ? ' synapzai-chat-reset-fixed' : '')}
          >
            <span onClick={() => this.context.resetChat()}>Reset Chat</span>
          </div>
        }
        <FeedbackPanel />
      </div>
    );
  }
}
    
export default ChatLog;