import { fetchPillSystem } from "../api/requests";
import { INVOKE_SCRIPT_ID } from "../constants";
import { CANCEL_PILL, PILL_TYPES } from "./pills";

var pillFactory = {};

// formats the pills by converting any prompt templates into functionable code
pillFactory.formatPills = (rawPills, addCancel = false) => {
    let pills = rawPills;

    for (let pill of pills) {
        if (pill.nestedPills) {
            pillFactory.formatPills(pill.nestedPills, true);
        }

        if (pill.type == PILL_TYPES.PRODUCT) {
            pill.nestedPills = products => {
                let _products = products.map(product => (
                    {
                        prompt: pill.prompt.replace('%{PRODUCT}', product.name),
                        title: product.name
                    }
                ))
    
                return _products.concat([
                    {
                        prompt: CANCEL_PILL,
                        title: 'Cancel',
                    }
                ]);
            }
        }
    }

    if (addCancel) {
        pills.push({
            prompt: CANCEL_PILL,
            title: 'Cancel',
        });
    }
    
    return pills;
}

// fetches the client pills from the server, returns a Promise
pillFactory.fetchClientPills = () => {
    return new Promise(async (resolve, reject) => {
        const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
        const clientId = scriptTag.getAttribute('data-client-id');

        try {
            let pills = await fetchPillSystem(clientId);
            resolve(pills);
        } catch(e) {
            reject(e);
        }
    });
}

export default pillFactory;