import { CHAT_API_LAMBDA_BACKEND, INVOKE_SCRIPT_ID, MOBILE_BREAKPOINT_WIDTH, PILLS_API_LAMBDA_BACKEND, PRODUCT_ITEM_WIDTH_STANDARD, REQUEST_PRODUCT_QUANTITY, SEND_FEEDBACK_API_LAMBDA_BACKEND, UPDATE_FEEDBACK_API_LAMBDA_BACKEND } from "../constants";

/**
 * resolve and return the number of products to request
 * this is dictated by various factors, such as the number of ui products that
 * can fit in one row, whether its mobile and whether the data-minimum-request-quantity has been set or not
 */
function getRequestQuantity() {
    if (window.innerWidth <= MOBILE_BREAKPOINT_WIDTH) {
        return REQUEST_PRODUCT_QUANTITY;
    }

    const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
    let chatLog = document.querySelector('.synapzai-chatlog');

    // if the chatlog is present check the amount of products we can fit in it
    if (chatLog) {
        let requestQuantityVar = scriptTag.getAttribute('data-minimum-request-quantity');
        let divisions = Math.round(chatLog.offsetWidth / PRODUCT_ITEM_WIDTH_STANDARD);

        // if the mininum request var exists on the invoker then check if its
        // more than the number of divisions, if it is then return that else return
        // the number of divisions
        if (requestQuantityVar) {
            let _requestQuantity = parseInt(requestQuantityVar);

            if (!isNaN(_requestQuantity)) {
                if (_requestQuantity > divisions) {
                    return _requestQuantity;
                }
            }
        }

        return divisions;
    }

    return REQUEST_PRODUCT_QUANTITY;
}

/**
 * sends the user query with the chat history to the chat api
 * @param {ChatRequestObject} query
 * @returns {Promise<ChatResponse>}
 */
export async function sendChatQueryRequest(chatRequestObject) {
    const response = await fetch(CHAT_API_LAMBDA_BACKEND, {
        method: "POST",
        body: JSON.stringify({ 
            chat_history: chatRequestObject.chatHistory,
            query: chatRequestObject.query,
            client_id: chatRequestObject.clientId,
            data_key: chatRequestObject.dataKey,
            prev_query: chatRequestObject.prevQuery,
            prev_entities: chatRequestObject.prevEntities,
            original_query: chatRequestObject.originalQuery,
            prev_product_query: chatRequestObject.prevProductQuery,
            request_quantity: getRequestQuantity()
        })
    });

    return await response.json();
}

/**
 * fetches the client's pill system for the widget
 * @param {ChatRequestObject} query
 * @returns {Promise<ChatResponse>}
 */
export async function fetchPillSystem(clientId) {
    const response = await fetch(PILLS_API_LAMBDA_BACKEND, {
        method: "POST",
        body: JSON.stringify({ 
            client_id: clientId,
        })
    });

    return await response.json();
}

/**
 * sends a cold start ping to backend lambda to warm it up if it hasnt warmed up already
 */
export function warmUpBackend() {
    fetch(CHAT_API_LAMBDA_BACKEND, {
        method: "POST",
        body: JSON.stringify({ 
            cold_start: true
        })
    }).then(response => {
        console.log('Cold starting backend...');
    });
}

/**
 * sends feedback to the server
 */
export async function sendFeedbackRequest(positive, clientId, chatlog, metadata) {
    let response = await fetch(SEND_FEEDBACK_API_LAMBDA_BACKEND, {
        method: "POST",
        body: JSON.stringify({ 
            positive: positive,
            client_id: clientId,
            chatlog,
            metadata
        })
    });

    return response.json();
}

/**
 * sends feedback to the server
 */
export async function updateFeedbackRequest(feedbackId, comment) {
    let response = await fetch(UPDATE_FEEDBACK_API_LAMBDA_BACKEND, {
        method: "POST",
        body: JSON.stringify({ 
            feedback_id: feedbackId,
            comment: comment,
        })
    });

    return response.json();
}