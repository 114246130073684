export const PILL_TYPES = {
    DEFAULT: 0,
    PRODUCT: 1
};

export const CANCEL_PILL = 'cancel';

export const pills = [
    {
        prompt: 'Show me more',
        title: 'Show me more',
    },
    {
        prompt: 'Are there other colours?',
        title: 'Are there other colours?',
        nestedPills: [
            {
                prompt: 'In black',
                title: 'Black',
            },
            {
                prompt: 'In white',
                title: 'White',
            },
            {
                prompt: 'In blue',
                title: 'Blue',
            },
            {
                prompt: 'In brown',
                title: 'Brown',
            },
            {
                prompt: 'In green',
                title: 'Green',
            },
            {
                prompt: 'In red',
                title: 'Red',
            },
            {
                prompt: 'In yellow',
                title: 'Yellow',
            },
            {
                prompt: CANCEL_PILL,
                title: 'Cancel',
            }
        ]
    },
    {
        prompt: 'Is there anything cheaper?',
        title: 'Is there anything cheaper?',
    },
    {
        prompt: 'What can I wear with this?',
        title: 'What can I wear with this?',
        nestedPills: [
            {
                prompt: 'What jacket can I wear with this?',
                title: 'Jacket',
                type: PILL_TYPES.PRODUCT,
                nestedPills: (products) => {
                    let _products = products.map(product => (
                        {
                            prompt: `What jacket can I wear with ${product.name}`,
                            title: product.name
                        }
                    ))

                    return _products.concat([
                        {
                            prompt: CANCEL_PILL,
                            title: 'Cancel',
                        }
                    ]);
                }
            },
            {
                prompt: 'What jeans can I wear with this?',
                title: 'Jeans',
                type: PILL_TYPES.PRODUCT,
                nestedPills: (products) => {
                    let _products = products.map(product => (
                        {
                            prompt: `What jeans can I wear with ${product.name}`,
                            title: product.name
                        }
                    ))

                    return _products.concat([
                        {
                            prompt: CANCEL_PILL,
                            title: 'Cancel',
                        }
                    ]);
                }
            },
            {
                prompt: 'What accesories can I wear with this?',
                title: 'Accesories',
                type: PILL_TYPES.PRODUCT,
                nestedPills: (products) => {
                    let _products = products.map(product => (
                        {
                            prompt: `What accesories can I wear with ${product.name}`,
                            title: product.name
                        }
                    ))

                    return _products.concat([
                        {
                            prompt: CANCEL_PILL,
                            title: 'Cancel',
                        }
                    ]);
                }
            },
            {
                prompt: 'What shoes can I wear with this?',
                title: 'Shoes',
                type: PILL_TYPES.PRODUCT,
                nestedPills: (products) => {
                    let _products = products.map(product => (
                        {
                            prompt: `What shoes can I wear with ${product.name}`,
                            title: product.name
                        }
                    ))

                    return _products.concat([
                        {
                            prompt: CANCEL_PILL,
                            title: 'Cancel',
                        }
                    ]);
                }
            },
            {
                prompt: 'What shirt can I wear with this?',
                title: 'Shirt',
                type: PILL_TYPES.PRODUCT,
                nestedPills: (products) => {
                    let _products = products.map(product => (
                        {
                            prompt: `What shirt can I wear with ${product.name}`,
                            title: product.name
                        }
                    ))

                    return _products.concat([
                        {
                            prompt: CANCEL_PILL,
                            title: 'Cancel',
                        }
                    ]);
                }
            },
            {
                prompt: CANCEL_PILL,
                title: 'Cancel',
            }
        ]
    },
]

export default pills;