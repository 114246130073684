import { useContext } from 'react';
import './misc.css';
import { MainContext } from '../../context/MainContext';

function Spinner() {
  const { widgetOpen } = useContext(MainContext);
  let className = 'synapz-spinner-box';

  if (!widgetOpen) {
    className += ' cta-widget-closed';
  }

  return (
    <div className={className}>
      <div className="synapz-blue-orbit synapz-leo">
      </div>

      <div className="synapz-green-orbit synapz-leo">
      </div>
      
      <div className="synapz-red-orbit synapz-leo">
      </div>
      
      <div className="synapz-white-orbit synapz-w1 synapz-leo">
      </div><div className="synapz-white-orbit synapz-w2 synapz-leo">
      </div><div className="synapz-white-orbit synapz-w3 synapz-leo">
      </div>
    </div>
  );
}
  
export default Spinner;