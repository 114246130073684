
function AutoFillOption(props) {
  return (
    <div
      onMouseOver={props.onMouseOver}
      className={"synapzai-autofill-option" + (props.highlighted ? ' synapzai-autofill-option-highlighted' : '')}
    >
      <span>{props.autoFillOption}</span>
    </div>
  );
}

export default AutoFillOption;