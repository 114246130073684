import { useEffect, useRef, useState, useContext } from "react";
import { MainContext } from "../../context/MainContext";
import thumbsDownIcon from '../../assets/thumbs_down.png';
import thumbsUpIcon from '../../assets/thumbs_up.png';
import { sendFeedbackRequest, updateFeedbackRequest } from "../../api/requests";
import { getUserBrowser, getUserDevice } from "../../utils/device";

const FEEDBACK_STATES = {
    initial: 0,
    sendFeedback: 1,
    feedbackSent: 2,
}

function FeedbackPanel() {
    const { chatHistory, clientId, sendingQuery } = useContext(MainContext);
    const [currentState, setCurrentState] = useState(FEEDBACK_STATES.initial);
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [feedbackId, setFeedbackId] = useState(null);
    const [comment, setComment] = useState('');
    const textAreaRef = useRef();

    // reset feedback state on chatHistory change
    useEffect(() => {
        setCurrentState(FEEDBACK_STATES.initial);
    }, [chatHistory]);

    const sendFeedback = (positive) => {
        setCurrentState(FEEDBACK_STATES.sendFeedback);
        let metadata = {
            browser: getUserBrowser(),
            device: getUserDevice(),
        };

        sendFeedbackRequest(positive, clientId, chatHistory, metadata).then(response => {
            setFeedbackId(response.feedback_id);
            setFeedbackSent(true);

            // scroll to bottom of chat log
            setTimeout(() => {
                // scroll to bottom of chatlog
                let chatLog = document.querySelector('.synapzai-chatlog');
                chatLog.scrollTop = chatLog.scrollHeight;

                setTimeout(() => {
                    textAreaRef.current.focus();
                }, 100);
            });
        });
    }

    const sendFeedbackMessage = () => {
        setCurrentState(FEEDBACK_STATES.feedbackSent);
        setComment('');
        updateFeedbackRequest(feedbackId, textAreaRef.current.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          sendFeedbackMessage();
        }
    };

    const renderState = () => {
        if (currentState === FEEDBACK_STATES.initial) {
            return (
                <div className="synmio-feedback-thumbs">
                    <img src={thumbsUpIcon} onClick={() => sendFeedback(true)} />
                    <img src={thumbsDownIcon} onClick={() => sendFeedback(false)} />
                </div>
            );
        }

        if (currentState === FEEDBACK_STATES.sendFeedback) {
            return (
                <div className="synmio-feedback-form">
                    <p>Tell us more about your experience</p>
                    <textarea 
                        onKeyDown={(e) => handleKeyPress(e)} 
                        ref={textAreaRef} 
                        placeholder="Enter here..."
                        onChange={e => setComment(e.target.value)}
                    >
                    </textarea>
                    <button 
                        className={comment.length > 0 ? 'active' : 'inactive'} 
                        onClick={() => sendFeedbackMessage()}>
                        Submit
                    </button>
                </div>
            );
        }

        if (currentState === FEEDBACK_STATES.feedbackSent) {
            return (
                <div className="synmio-feedback-sent">
                    <p>Thank you for your feedback!</p>
                </div>
            )
        }
    }

    if (chatHistory.length < 3 || sendingQuery) {
        return null;
    }

    return (
      <div className='synmio-feedback-panel'>
        {renderState()}
      </div>
    );
  }
  
  export default FeedbackPanel;