import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import closeIcon from '../../assets/close.png';

function ChatHeader(props) {
  const { branding, setWidgetOpen } = useContext(MainContext);

  var classNames = 'synapzai-chat-header';

  if (props.chatLogShouldShow) {
    classNames += ' synapzai-chat-header-opened';
  }

  if (props.disableCollapse) {
    classNames += ' synapzai-chat-header-disabled';
  }

  return (
    <div onClick={() => !props.disableCollapse && setWidgetOpen(false)} className={classNames}>
      <p>{branding}</p>
      {!props.disableCollapse &&
        <div className="synapzai-collapse-container">
          <img src={closeIcon} />
        </div>
      }
    </div>
  );
}
    
export default ChatHeader;