
// gets the browser device
export function getUserDevice() {
    const userAgent = navigator.userAgent;

    if (/mobile/i.test(userAgent)) return 'Mobile';
    if (/like Mac OS X/.test(userAgent)) return 'iOS';
    if (/Android/.test(userAgent)) return 'Android';
    if (/webOS/.test(userAgent)) return 'webOS';
    if (/Intel Mac OS X/.test(userAgent)) return 'Mac';
    if (/Windows NT/.test(userAgent)) return 'Windows';

    return 'Unknown';
}

// gets the browser
export function getUserBrowser() {
    const userAgent = navigator.userAgent;

    if (/Edg/.test(userAgent)) return 'Edge';
    if (/Chrome/.test(userAgent)) return 'Chrome';
    if (/Safari/.test(userAgent)) return 'Safari';
    if (/Firefox/.test(userAgent)) return 'Firefox';
    if (/MSIE/.test(userAgent) || /Trident/.test(userAgent)) return 'IE';

    return 'Unknown';
}