import { useState } from "react";
import AutoFillOption from "./AutoFillOption";
import './autofill.css';

function AutoFillContainer(props) {
  const highlightedIndex = props.highlightedIndex ?? 0;

  const onOptionSelect = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onAutoFillOptionSelect();
  }

  return (
    <div 
      style={props.style}
      onClick={onOptionSelect}
      className="synapzai-autofill-container"
    >
      {props.autoFillOptions.map((option, index) => (
        <AutoFillOption 
          highlighted={highlightedIndex == index} 
          key={index} 
          autoFillOption={option}
          onMouseOver={() => props.setAutoFillIndex(index)}
        />
      ))}
    </div>
  );
}

export default AutoFillContainer;