import { ROLE_ASSISTANT, ROLE_USER } from "../../constants";
import { replaceUrlsWithLinks } from "../../utils/string";
import '../Misc/loaders.css';

function MessageBubble(props) {
  let transformedMessage = '';
  let messageBubbleClassName = 'synapzai-message-bubble';
  
  if (props.message) {
    transformedMessage = props.message.replaceAll('\n', '<br />');
  }

  if (props.role == ROLE_USER) {
    messageBubbleClassName += ' synapzeai-user-role';
  } else if (props.role == ROLE_ASSISTANT) {
    messageBubbleClassName += ' synapzeai-assistant-role';
  }

  if (props.isTypingBubble) {
    messageBubbleClassName += ' synapzai-typing-bubble';
  }

  return (
    <div className="synapzeai-chatlog-row">
      <div className={messageBubbleClassName}>
        {
          props.isTypingBubble ?
          <div className="load-3">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
          :
          <span dangerouslySetInnerHTML={{ __html: transformedMessage }} />
        }
      </div>
    </div>
  );
}

export default MessageBubble;