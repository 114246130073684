/**
 * prefixes the provided price string with the current locale's currency symbol
 * @param {String} price string 
 */
export function prefixWithLocaleCurrency(price, currency = 'GBP') {
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: currency });
    
    // Check if the price already starts with a currency symbol
    if (!price.trim().match(/^\p{Sc}/u)) {
        // If not, prefix with the currency symbol based on the current locale
        const formattedPrice = formatter.format(parseFloat(price));
        return formattedPrice;
    } else {
        // If the price already has a currency symbol, return as is
        return price;
    }
}

/**
 * replaces raw string links with html links
 * @param {String} inputString the raw string 
 */
export function replaceUrlsWithLinks(inputString) {
    // Define a regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with HTML links
    const replacedString = inputString.replace(urlRegex, (url) => {
        return `<a href='${url}' target='_blank'>${url}</a>`;
    });

    return replacedString;
}