import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import QueryInput from "./QueryInput";
import ChatHeader from "./ChatHeader";
import ChatLog from "../ChatLog/ChatLog";
import { 
  INITIAL_CHATLOG_HEIGHT, 
  INITIAL_CHAT_CONTAINER_HEIGHT, 
  INITIAL_QUERY_INPUT_HEIGHT, 
  INVOKE_SCRIPT_ID, 
  MAXIMUM_CHAT_CONTAINER_HEIGHT,
  MOBILE_BREAKPOINT_WIDTH,
} from "../../constants";
import './core.css';
import WidgetCTA from "./WidgetCTA";

function ChatContainer() {
  const { 
    chatHistory, 
    widgetOpen, 
    queryInputHeight, 
    sendingQuery,
    windowHeight,
  } = useContext(MainContext);
  var chatLogShouldShow = chatHistory.length > 0;
  const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
  const chatbotMode = scriptTag.getAttribute('data-chatbot-mode') ? true : false;
  const forceFullHeightMobile = scriptTag.getAttribute('data-force-full-height-mobile') ? true : false;

  // if we have chatbot mode on we always show the chatlog
  if (chatbotMode) {
    chatLogShouldShow = true;
  }

  var containerHeight = INITIAL_CHAT_CONTAINER_HEIGHT;
  var chatLogHeight = 0;

  if (chatLogShouldShow) {
    chatLogHeight = Math.max(INITIAL_CHATLOG_HEIGHT - queryInputHeight, 0);
    containerHeight = MAXIMUM_CHAT_CONTAINER_HEIGHT;

    if (window.innerWidth <= MOBILE_BREAKPOINT_WIDTH && (!chatbotMode || forceFullHeightMobile)) {
      containerHeight = windowHeight;
      chatLogHeight = containerHeight - (queryInputHeight + 44);
    }
  } else {
    containerHeight = Math.max(INITIAL_CHAT_CONTAINER_HEIGHT, (INITIAL_CHAT_CONTAINER_HEIGHT - INITIAL_QUERY_INPUT_HEIGHT) + queryInputHeight);
  }

  var classNames = 'synapzai-chat-container';

  if (widgetOpen && chatLogShouldShow) {
    classNames += ' synapzai-chat-container-opened';
  } else if (!widgetOpen) {
    classNames += ' synapzai-collapsed';
  }

  // if there is an insertion clause then we need the position to be relative
  const insertAfterSelector = scriptTag.getAttribute('data-insert-after');
  if (insertAfterSelector) {
    classNames += ' synapzai-chat-relative';
  }

  return (
    <div className={classNames} style={{ height: containerHeight + 'px' }}>
      <ChatHeader disableCollapse={chatbotMode} chatLogShouldShow={chatLogShouldShow} />
      <ChatLog chatHistory={chatHistory} chatLogHeight={chatLogHeight} />
      <QueryInput sendingQuery={sendingQuery} />
      {insertAfterSelector && <WidgetCTA chatOnlyMode={true} />}
    </div>
  );
}

export default ChatContainer;