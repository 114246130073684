import moment from 'moment';
import { INVOKE_SCRIPT_ID } from '../constants';

const SYNAPZAI_SESSION_DATA = 'SYNAPZAI_SESSION_DATA';

interface SessionData {
  widgetOpen: boolean;
  chatHistory: [];
  notificationCount: number;
  expiryDate: number;
  pendingQuery: string | null;
}

class Session {
  sessionData: SessionData;

  // construct a new SessionData object
  // if there is a session persiststed on device then fetch that session
  constructor() {
    this.sessionData = this.getInitialSessionState();
    
    if (!this.isSessionEnabled()) {
      return;
    }

    let sessionDataRaw = localStorage.getItem(SYNAPZAI_SESSION_DATA);

    // if the session data exists, check its expiry
    // if it is still valid then load the session data
    if (sessionDataRaw) {
      let sessionDataParsed = JSON.parse(sessionDataRaw) as SessionData;

      if (sessionDataParsed) {
        if (moment().unix() <= sessionDataParsed.expiryDate) {
          this.sessionData = sessionDataParsed;
        }
      }
    }
  }

  // gets the initial session state
  getInitialSessionState() : SessionData {
    return {
      widgetOpen: false,
      chatHistory: [],
      notificationCount: 0,
      expiryDate: moment().add(1, 'hour').unix(),
      pendingQuery: null
    } as SessionData
  }

  // clears the session and restores it to its initial session
  flushSession() {
    this.sessionData = this.getInitialSessionState();
    this.save();
  }

  // update widget status on session and persist it
  toggleWidgetStatus(widgetStatus: boolean) {
    this.sessionData.widgetOpen = widgetStatus;
    this.save();
  }

  // update chat history tied to session and persist it
  updateChatHistory(newChatHistory: []) {
    this.sessionData.chatHistory = newChatHistory;
    this.save();
  }

  // update session notification count
  updateNotificationCount(notificationCount: number) {
    this.sessionData.notificationCount = notificationCount;
    this.save();
  }

  getNotificationCount() : number {
    return this.sessionData.notificationCount;
  }

  // returns if the widget is open on the session
  getWidgetStatus() : boolean {
    return this.sessionData.widgetOpen;
  }

  // gets the persisted chat history
  getChatHistory() : [] {
    return this.sessionData.chatHistory;
  }

  // pending query is a query that is was still processing if the user left the page
  // we store so we can requeue the process should the user revisit
  setPendingQuery(query: string | null) {
    this.sessionData.pendingQuery = query;
    this.save();
  }

  // get pending query
  getPendingQuery() : string | null {
    return this.sessionData.pendingQuery;
  }

  // checks if we have session persistence
  isSessionEnabled() : boolean {
    const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
    const enableSession = scriptTag?.getAttribute('data-enable-session');
    return !!enableSession;
  }

  /**
   * Saves the session and persists it to localStorage
   */
  save() {
    // caller of script has session persistence disabled
    if (!this.isSessionEnabled()) {
      return;
    }

    if (this.sessionData) {
      localStorage.setItem(SYNAPZAI_SESSION_DATA, JSON.stringify(this.sessionData));
    }
  }
}


export default Session;