import { useContext } from "react";
import { MainContext } from '../../context/MainContext';
import { CANCEL_PILL } from "../../utils/pills";

function Pill(props) {
    const { sendChatQuery } = useContext(MainContext);

    const onClick = (pill) => {
        if (pill.nestedPills && pill.nestedPills.length > 0) {
            props.openNestedPills(pill);
        } else if (pill.prompt == CANCEL_PILL) {
            props.goBack();
        } else {
            sendChatQuery(pill.prompt);
            props.resetPills();
        }
    }

    return (
        <div onClick={() => onClick(props.pill)} className={"synapzai-pill" + (props.pill.prompt == CANCEL_PILL ? ' synapzai-pill-cancel' : '')}>
            <span>{props.pill.title}</span>
        </div>
    );
}

export default Pill;