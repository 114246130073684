import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './mobile.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { warmUpBackend } from './api/requests';
import { SYNAPZAI_BOT_ID, SYNAPZAI_ADAPTER_ID, INVOKE_SCRIPT_ID } from './constants';
import { getContrastColor } from './utils/ui';
import { incrementLoadCount } from './utils/visit_count';

// applies the base style as a <style> element
const applyBaseStyle = async () => {
  let head = document.head || document.getElementsByTagName('head')[0];

  // first fetch external style then add variables
  let externalStyleResponse = await fetch('https://distribution-assets.s3.eu-west-2.amazonaws.com/dist.css');
  let externalStyleText = await externalStyleResponse.text();
  var style = document.createElement('style');
  head.appendChild(style);

  if (style.styleSheet){
    // This is required for IE8 and below.
    style.styleSheet.cssText = externalStyleText;
  } else {
    style.appendChild(document.createTextNode(externalStyleText));
  }

  // now add in variable styles
  const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
  const primaryColor = scriptTag.getAttribute('data-primary-color') ?? '#34345B';
  const secondaryColor = scriptTag.getAttribute('data-secondary-color') ?? '#F2E9E4';
  const font = scriptTag.getAttribute('data-font') ?? 'Poppins';
  const primaryContrastColor = getContrastColor(primaryColor);
  const secondaryContrastColor = getContrastColor(secondaryColor);
  const tertiaryColor = scriptTag.getAttribute('data-tertiary-color') ?? 'rgba(0, 0, 0, 0.3)';
  const tertiaryContrastColor = getContrastColor(tertiaryColor);
  const ctaColor = scriptTag.getAttribute('data-cta-color') ?? 'rgba(0, 0, 0, 0.3)';
  const ctaContrastColor = getContrastColor(ctaColor);

  var css = `
    :root {
      --primary-font: '${font}';
      --secondary-font: '${font}';
      --primary-color: ${primaryColor};
      --secondary-color: ${secondaryColor};
      --primary-color-font-color: ${primaryContrastColor};
      --secondary-color-font-color: ${secondaryContrastColor};
      --tertiary-color: ${tertiaryColor};
      --tertiary-color-font-color: ${tertiaryContrastColor};
      --cta-color: ${ctaColor};
      --cta-color-font-color: ${ctaContrastColor};
    }
  `;

  var variableStyles = document.createElement('style');
  head.appendChild(variableStyles);

  if (variableStyles.styleSheet){
    // This is required for IE8 and below.
    variableStyles.styleSheet.cssText = css;
  } else {
    variableStyles.appendChild(document.createTextNode(css));
  }
}

const initialiseSynapz = async () => {
  var container = document.createElement('div');
  container.id = SYNAPZAI_BOT_ID;
  document.body.appendChild(container);

  // increment widget load count
  incrementLoadCount();

  // used for determining element client height for things like iOS Safari
  // which changes the window.innerHeight property whenever the keyboard becomes present
  // this is used to get the height of the window without factoring in the keyboard
  var adapter = document.createElement('div');
  adapter.id = SYNAPZAI_ADAPTER_ID;
  document.body.appendChild(adapter);

  // if we have an insert after clause we send the react root element to be inserted
  // after the selector in the insert after clause
  const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
  const insertAfterSelector = scriptTag.getAttribute('data-insert-after');

  if (insertAfterSelector) {
    const el = document.querySelector(insertAfterSelector);

    if (el) {
      el.after(container);
    } else {
      // do not attach chatbot if no insertion element is present
      adapter.remove();
      container.remove();
      return;
    }
  }

  await applyBaseStyle();

  const root = ReactDOM.createRoot(document.getElementById(SYNAPZAI_BOT_ID));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

  // warm up the backend
  warmUpBackend();
}

initialiseSynapz();