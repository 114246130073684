import React, { Component } from "react";
import { MainContext } from "../../context/MainContext";
import placeholders from '../../utils/placeholders';
import { INITIAL_QUERY_INPUT_HEIGHT, INVOKE_SCRIPT_ID, MOBILE_BREAKPOINT_WIDTH, PLACEHOLDER_CATEGORY } from "../../constants";
import AutoFillContainer from "../AutoFill/AutoFillContainer";
import { getAllChildren } from "../../utils/ui";

class QueryInput extends Component {

  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.editor = React.createRef();
    this.counterTimer = null;

    this.state = {
      ellipsisCounter: 1,
      showAutoFill: false,
      autoFillIndex: 0,
    };
  }

  componentDidUpdate() {
    // we have a timer that updates the ellipsis placeholder counter when
    // loading results
    if (this.props.sendingQuery) {
      if (this.counterTimer != null) {
        clearInterval(this.counterTimer);
        this.counterTimer = null;
      }

      this.counterTimer = setInterval(() => {
        const { ellipsisCounter } = this.state;

        this.setState({
          ellipsisCounter: ellipsisCounter > 2 ? 1 : ellipsisCounter + 1
        });
      }, 500);
    } else {
      if (this.counterTimer != null) {
        clearInterval(this.counterTimer);
        this.counterTimer = null;
      }
    }
  }

  /**
   * gets the editor text
   */
  getEditorText = () => {
    if (!this.editor.current) {
      return '';
    }

    return this.editor.current.innerText || this.editor.current.textContent;
  }

  /**
   * gets the current highlighted option in the autofill component
   */
  getCurrentHighlightedEntity = () => {
    const { autoFillIndex } = this.state;
    let entities = this.getAutoFillEntities();
    return entities[autoFillIndex];
  }

  /**
   * gets available autofill options
   */
  getAutoFillEntities = () => {
    const { getPrevEntities } = this.context;
    const entities = getPrevEntities();

    if (!this.editor.current || !entities) {
      return [];
    }

    var editorText = this.getEditorText();
    var index = editorText.lastIndexOf('with');
    editorText = editorText.slice(index).trim().replace('with', '').trim();

    return entities.filter(entity => {
      if (editorText.length == 0) {
        return true;
      }

      return entity.name.toLowerCase().startsWith(editorText.toLowerCase());
    });
  }

  // triggered on click of the autofill or when the user hits enter with
  // the autofill component open
  onAutoFillOptionSelect = () => {
    const { sendChatQuery } = this.context;
    let entity = this.getCurrentHighlightedEntity();
    var editorText = this.getEditorText();
    var parts = editorText.split('with ');
    
    // remove user typing of autofill option
    if (parts.length > 1) {
      let part = parts[parts.length - 1];
      editorText = editorText.replace(part, '');
    }

    // set editor text to current text + autofilled component
    this.editor.current.innerText = editorText + entity.name;
    this.setState({
      showAutoFill: false
    });

    // then resize the text area and change caret position to end of editor
    setTimeout(() => {
      this.onResizeTextArea();

      var sel = window.getSelection();
      var range = document.createRange();
    
      range.setStart(this.editor.current.childNodes[0], this.editor.current.innerText.length);
      range.collapse(true);
      
      sel.removeAllRanges();
      sel.addRange(range);
    })
  }

  /**
   * checks the status of the autofill and if it matches three characters
   * of an autofill option it auto completes the string
   * returns true if successful
   */
  onShouldAutoFill = () => {
    let entity = this.getCurrentHighlightedEntity();
    var editorText = this.getEditorText();
    var parts = editorText.split('with ');
    
    // remove user typing of autofill option
    if (parts.length > 1 && this.getAutoFillEntities().length == 1) {
      let part = parts[parts.length - 1].trim();
      if (part.length > 2 && entity.name.toLowerCase().startsWith(part.toLowerCase())) {
        editorText = editorText.replace(part, entity.name);
        this.editor.current.innerText = editorText;

        // resize text area
        setTimeout(() => {
          this.onResizeTextArea();

          // place caret at end
          var el = this.editor.current;
          if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
          }
        });

        return true;
      }
    }

    return false;
  }

  /**
   * resize the editable text area so the text fits in and the area expands
   */
  onResizeTextArea = () => {
    if (!this.editor.current) {
      return;
    }
    
    const { setQueryInputHeight } = this.context;
    var textInputHeight = this.editor.current.scrollHeight;

    // create an anchor point so we can calculate where the bottom of the text is
    // in order to scale the editor height correctly
    let anchor = document.createElement('div');
    anchor.className = 'synapzai-query-input-anchor';
    this.editor.current.appendChild(anchor);
    textInputHeight = anchor.offsetTop + 10;
    anchor.remove();

    setQueryInputHeight(textInputHeight);
  }

  // handle enter and key types
  onKeyDown = (e) => {
    const { 
      sendChatQuery, 
      setQueryInputHeight,
      setQueryText,
      getPrevEntities
    } = this.context;

    const { 
      autoFillIndex, 
      showAutoFill,
    } = this.state;

    var editorText = this.getEditorText();

    // control upwards movement of autofill component
    if ((e.key === 'ArrowUp' || e.keyCode === 38) && showAutoFill) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        autoFillIndex: Math.max(0, autoFillIndex - 1)
      })
      return;
    }

    // control downwards movement of autofill component
    if ((e.key === 'ArrowDown' || e.keyCode === 40) && showAutoFill) {
      e.preventDefault();
      e.stopPropagation();
      let entities = this.getAutoFillEntities();
      this.setState({
        autoFillIndex: Math.min(entities.length - 1, autoFillIndex + 1)
      })
      return;
    }

    // if control is pressed as well as enter we insert a new line
    // if (e.keyCode === 13 && e.shiftKey) {
    //   e.preventDefault();
    //   this.insertNewLine();

    //   setTimeout(() => {
    //     this.onResizeTextArea();
    //   });

    //   return;
    // }

    // enter key, send the query
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();

      if (this.getEditorText().length == 0) {
        return;
      }

      setQueryText('');

      // if autofill is showing then fill out and send
      // else just send normally
      if (showAutoFill) {
        this.onAutoFillOptionSelect();
        return;
      } else {
        sendChatQuery(editorText.trim());
      }

      this.editor.current.innerText = '';
      this.setState({
        showAutoFill: false
      });

      setTimeout(() => {
        // reset text area size
        this.onResizeTextArea();

        // scroll to bottom of chatlog
        let chatLog = document.querySelector('.synapzai-chatlog');
        chatLog.scrollTop = chatLog.scrollHeight;
      });

      return;
    }

    /**
     * we need to check if the user has typed a sequence which triggers autofill
     * this is done on a timeout so that the event has propagated to the editor
     * and the current editor text can be retrieved
     */
    setTimeout(() => {
      // resize text area on type
      this.onResizeTextArea();
      editorText = this.getEditorText();
      setQueryText(editorText);

      // check for 'with' in this case we show possible entities to autofill
      // for example 'i want black shoes with ...'
      if (editorText.slice(0, -1).endsWith('with') && getPrevEntities() && !showAutoFill) {
        this.setState({
          showAutoFill: true
        });
      } else {
        // potential auto complete of an entity
        // only continue if the operation isnt a deletion i.e backspace
        if (e.key != 'Backspace' && e.keyCode != 8 && !e.getModifierState('Accel')) {
          if (this.onShouldAutoFill()) {
            return this.setState({
              showAutoFill: false,
              autoFillIndex: 0
            });
          }
        }

        // if there are autofill entities then we reset the index
        // so the top most entity is always selected
        let entities = this.getAutoFillEntities();

        if (entities.length > 0 && editorText.length > 0) {
          this.setState({
            autoFillIndex: 0
          });
        } else {
          this.setState({
            showAutoFill: false,
            autoFillIndex: 0
          });
        }
      }
    }, 100);
  }

  // inserts a new line in the editor
  insertNewLine = () => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    
    // Create a new line (div with contentEditable set to false)
    const newline = document.createElement('br');
    
    // Insert the new line
    range.deleteContents();
    range.insertNode(newline);
    
    // Move the cursor to the end of the new line
    range.setStartAfter(newline);
    range.setEndAfter(newline);
    
    // Update the selection
    selection.removeAllRanges();
    selection.addRange(range);
  };

  /**
   * Gets the position of the caret inside the text editor
   */
  getCaretPos = () => {
    const defaultVal = { x: 0, y: 0 };

    if (!this.container.current) {
      return defaultVal;
    }

    var selection = window.getSelection();

    if (!selection || selection.rangeCount < 1) {
      return defaultVal;
    }

    var range = selection.getRangeAt(0);
    let caretRect = range.getClientRects()[0];
    let containerRect = this.container.current.getBoundingClientRect();

    if (!caretRect) {
      return defaultVal;
    }

    return {
      x: caretRect.x - containerRect.x,
      y: caretRect.y - containerRect.y
    };
  }

  // checks if the current selection is a range (a ranged selection)
  isSelectionARange = () => {
    let selection = window.getSelection();

    if (!selection || selection.rangeCount < 1) {
      return false;
    }

    let range = selection.getRangeAt(0);
    return (range.endOffset - range.startOffset) > 0
  }

  /**
   * Only paste raw unformatted text into contenteditable
   * otherwise we'll get users pasting in html like images and documents into the query input
   */
  handlePaste = (e) => {
    e.preventDefault();

    // Get the copied text from the clipboard
    const text = e.clipboardData
        ? (e.originalEvent || e).clipboardData.getData('text/plain')
        : // For IE
        window.clipboardData
        ? window.clipboardData.getData('Text')
        : '';

    // Insert text at the current position of caret
    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    setTimeout(() => {
      // resize text area on type
      this.onResizeTextArea();

      const { setQueryText } = this.context;
      setQueryText(this.getEditorText());
    });
  }

  // makes sure the autofill box fits within the widget
  // otherwise it will offset out of the widgets bounds
  // if the cursor is toward the end of the query input
  clampAutoFillPosToParent(caretX) {
    let parent = document.querySelector('.synapzai-query-input-editor');
    let parentWidth = parent.offsetWidth + 50;
    let autofillRectMaxX = caretX + 150;

    if (autofillRectMaxX > parentWidth) {
      return caretX - (autofillRectMaxX - parentWidth);
    }

    return caretX
  }

  render() {
    const {
      queryInputHeight,
      queryText, 
      setQueryText,
      sendingQuery,
      getPrevEntities,
      windowWidth,
    } = this.context;

    const { 
      ellipsisCounter, 
      showAutoFill,
      autoFillIndex,
    } = this.state;

    var editorText = '';
    var entities = this.getAutoFillEntities() ?? [];
    const caretPos = this.getCaretPos();

    if (this.editor.current) {
      editorText = this.editor.current.innerText || this.editor.current.textContent;
      editorText = editorText.trim();
    }

    if (sendingQuery) {
      return (
        <div className="synapzai-query-input">
          <textarea
            value=""
            disabled
            className="synapzai-query-input-disabled"
            placeholder={'Handpicking the best choices for you' + ('.'.repeat(ellipsisCounter))}
          ></textarea>
        </div>
      )
    }

    return (
      <div 
        onClick={() => {
          setTimeout(() => this.editor.current.focus());
        }} 
        ref={this.container}
        className="synapzai-query-input"
      >
        <div
          className="synapzai-query-input-editor"
          contentEditable
          suppressContentEditableWarning={true}
          onKeyDown={this.onKeyDown}
          onPaste={this.handlePaste}
          onDrop={e => e.preventDefault()}
          onInput={this.onInput}
          ref={this.editor}
          style={{ height: queryInputHeight + 'px' }}
        >
        </div>
        {editorText.length == 0 &&
          (windowWidth > 500 ?
          <p className="synapzai-query-input-placeholder">
            <strong>Type:&nbsp;</strong>
            <span>{placeholders[PLACEHOLDER_CATEGORY].queryDesktop}</span>
          </p>
          :
          <p className="synapzai-query-input-placeholder">
            <span>{placeholders[PLACEHOLDER_CATEGORY].queryMobile}</span>
          </p>
          )
        }
        {showAutoFill && !this.isSelectionARange() &&
          <AutoFillContainer 
            style={{ left: this.clampAutoFillPosToParent(caretPos.x), height: (entities.length * 38) + 'px' }}
            onAutoFillOptionSelect={() => this.onAutoFillOptionSelect()}
            highlightedIndex={autoFillIndex}
            autoFillOptions={entities.map(e => e.name)}
            setAutoFillIndex={(autoFillIndex) => this.setState({ autoFillIndex })}
          />
        }
      </div>
    );
  }

}
    
export default QueryInput;