// primary invoke and element constants
export const INVOKE_SCRIPT_ID = 'synapz-invoke-script-000';
export const SYNAPZAI_BOT_ID = 'synapzai-widget'

// chat message role constants
export const ROLE_ASSISTANT = 'assistant';
export const ROLE_USER = 'user';
export const ROLE_ENTITIES = 'entities';
export const ROLE_RESET = 'reset';

// UI constants
export const INITIAL_QUERY_INPUT_HEIGHT = 44;
export const INITIAL_CHAT_CONTAINER_HEIGHT = 84;
export const PRODUCT_ITEM_WIDTH_STANDARD = 160;
export const PRODUCT_ITEM_WIDTH_MOBILE = 140;

const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
var maxChatContainerHeight = scriptTag.getAttribute('data-max-chat-container-height');

if (maxChatContainerHeight) {
    maxChatContainerHeight = parseInt(maxChatContainerHeight);
} else {
    maxChatContainerHeight = 600;
}

var maxChatLogHeight = maxChatContainerHeight - 40;

export const INITIAL_CHATLOG_HEIGHT = maxChatLogHeight;
export const MAXIMUM_CHAT_CONTAINER_HEIGHT = maxChatContainerHeight;
export const MOBILE_BREAKPOINT_WIDTH = 487;
export const SYNAPZAI_ADAPTER_ID = 'synapzai-adapter';

// ENV and backend api url constants
var environment = scriptTag.getAttribute('data-env');
export const SYNAPZAI_ENV = environment == 'production' ? 'production' : 'dev';

var chatApiBackend = 'https://fejpi7v1d5.execute-api.eu-west-2.amazonaws.com/dev/chat_v2';
var pillsApiBackend = 'https://fejpi7v1d5.execute-api.eu-west-2.amazonaws.com/dev/pills';
var sendFeedbackApiBackend = 'https://fejpi7v1d5.execute-api.eu-west-2.amazonaws.com/dev/send_feedback';
var updateFeedbackApiBackend = 'https://fejpi7v1d5.execute-api.eu-west-2.amazonaws.com/dev/update_feedback';

if (environment == 'production') {
    chatApiBackend = 'https://rx67bvhxhk.execute-api.eu-west-2.amazonaws.com/production/chat_v2';
    pillsApiBackend = 'https://rx67bvhxhk.execute-api.eu-west-2.amazonaws.com/production/pills';
    sendFeedbackApiBackend = 'https://rx67bvhxhk.execute-api.eu-west-2.amazonaws.com/production/send_feedback';
    updateFeedbackApiBackend = 'https://rx67bvhxhk.execute-api.eu-west-2.amazonaws.com/production/update_feedback';
}

export const CHAT_API_LAMBDA_BACKEND = chatApiBackend;
export const PILLS_API_LAMBDA_BACKEND = pillsApiBackend;
export const SEND_FEEDBACK_API_LAMBDA_BACKEND = sendFeedbackApiBackend;
export const UPDATE_FEEDBACK_API_LAMBDA_BACKEND = updateFeedbackApiBackend;

// Product request quantity constant
var requestProductQuantity = 3;
var requestProductQuantityVariable = scriptTag.getAttribute('data-minimum-request-quantity');

if (requestProductQuantityVariable) {
    let _requestProductQuantityVariable = parseInt(requestProductQuantityVariable);

    if (!isNaN(_requestProductQuantityVariable)) {
        requestProductQuantity = _requestProductQuantityVariable;
    }
}

export const REQUEST_PRODUCT_QUANTITY = requestProductQuantity;
export const PLACEHOLDER_CATEGORY = scriptTag.getAttribute('data-placeholder-category') ?? 'clothing1';
export const POPUP_TEXT = scriptTag.getAttribute('data-popup-text') ?? 'Click here to search products with AI powered natural language!';
export const SHOW_ADD_TO_CART_BUTTON = scriptTag.getAttribute('data-show-add-to-cart') == 'true' ? true : false;
export const DEFAULT_CURRENCY_SYMBOL = scriptTag.getAttribute('data-default-currency-symbol') ?? 'GBP';
export const PRODUCT_CTA_TEXT = scriptTag.getAttribute('data-cta-text') ?? 'Shop now';
export const LAST_TIME_ALERT_SOUND_PLAYED = 'SYNAPZ_LAST_TIME_ALERT_SOUND_PLAYED';
export const LEAVE_MESSAGE = scriptTag.getAttribute('data-leave-message');