import ChatContainer from './components/Core/ChatContainer';
import WidgetCTA from './components/Core/WidgetCTA';
import MainPopup from './components/Misc/MainPopup';
import { INVOKE_SCRIPT_ID } from './constants';
import { MainContextProvider } from './context/MainContext';

function App() {
  const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
  const insertAfterSelector = scriptTag.getAttribute('data-insert-after') ? true : false;

  return (
    <div className="synapzai-app">
      <MainContextProvider>
        <MainPopup />
        {!insertAfterSelector && <WidgetCTA />}
        <ChatContainer />
      </MainContextProvider>
    </div>
  );
}

export default App;
