import { useState, useContext, useEffect } from "react";
import { PILL_TYPES } from "../../utils/pills";
import Pill from "./Pill";
import { MainContext } from "../../context/MainContext";
import './pill.css';

function PillContainer() {
    const { 
        getPrevEntities, 
        widgetOpen,
        chatHistory,
        resetChat,
        pills,
    } = useContext(MainContext);

    var [pillStack, setPillStack] = useState([pills]);

    const resetPills = () => {
        setPillStack([pills]);
    }

    const goBack = () => {
        var newPillStack = [].concat(pillStack);
        newPillStack.pop();
        setPillStack(newPillStack);
    }

    const openNestedPills = parent => {
        var nestedPills = parent.nestedPills;

        // product pill type have nested pills that are functions that require the products to be passed into the argument
        if (parent.type == PILL_TYPES.PRODUCT) {
            let products = getPrevEntities();
            nestedPills = nestedPills(products);
        }

        var newPillStack = [].concat(pillStack);
        newPillStack.push(nestedPills);
        setPillStack(newPillStack);
    }

    if (pillStack[0].length == 0) {
        pillStack = [pills];
    }

    let currentPills = pillStack[pillStack.length - 1];

    return (
        <div className="synapzai-pill-container">
            {currentPills.map((pill, index) => (
                <Pill 
                    key={index} 
                    pill={pill} 
                    openNestedPills={openNestedPills}
                    resetPills={resetPills} 
                    goBack={goBack}
                />
            ))}
        </div>
    );
}

export default PillContainer;