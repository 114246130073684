export const placeholders = {
    clothing1: {
        queryDesktop: 'Find some black shoes to go with my summer dress',
        queryMobile: '"Black shoes to go with a summer dress"'
    },
    tech1: {
        queryDesktop: '"Best cooling solutions for overclocking"',
        queryMobile: '"Best cooling solutions for overclocking"'
    },
    tech2: {
        queryDesktop: "What can we help with today?",
        queryMobile: "What can we help with today?"
    }
};

export default placeholders;