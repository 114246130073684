const SYNMIO_LOAD_COUNT_KEY = 'SYNMIO_LOAD_COUNT_KEY';
const SYNMIO_LEAVE_POPUP_SHOWN = 'SYNMIO_LEAVE_POPUP_SHOWN';
const SYNMIO_LEAVE_POPUO_SHOWN_TIME = 'SYNMIO_LEAVE_POPUO_SHOWN_TIME';

export function incrementLoadCount() {
    let visitCount = localStorage.getItem(SYNMIO_LOAD_COUNT_KEY) ?? 0;
    localStorage.setItem(SYNMIO_LOAD_COUNT_KEY, ++visitCount);
}

export function getLoadCount() {
    return localStorage.getItem(SYNMIO_LOAD_COUNT_KEY) ?? 0;
}

export function setLeavePopupShown() {
    localStorage.setItem(SYNMIO_LEAVE_POPUP_SHOWN, true);
}

export function getLeavePopupShown() {
    return localStorage.getItem(SYNMIO_LEAVE_POPUP_SHOWN);
}

export function setLeavePopupShownTime() {
    localStorage.setItem(SYNMIO_LEAVE_POPUO_SHOWN_TIME, Math.floor(Date.now() / 1000));
}

export function getLeavePopupShownTime() {
    return localStorage.getItem(SYNMIO_LEAVE_POPUO_SHOWN_TIME);
}