import React, { Component } from 'react'
import { INITIAL_QUERY_INPUT_HEIGHT, INVOKE_SCRIPT_ID, LAST_TIME_ALERT_SOUND_PLAYED, LEAVE_MESSAGE, ROLE_ASSISTANT, ROLE_ENTITIES, ROLE_RESET, ROLE_USER, SYNAPZAI_ADAPTER_ID } from '../constants';
import { sendChatQueryRequest, warmUpBackend } from '../api/requests';
import Session from '../utils/session.ts';
import pillFactory from '../utils/pill_factory';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { getLeavePopupShown, getLeavePopupShownTime, getLoadCount, setLeavePopupShown, setLeavePopupShownTime } from '../utils/visit_count.js';

const MainContext = React.createContext()

class MainContextProvider extends Component {
  constructor(props) {
    super(props);
    this.serverErrorCounter = 0;
    this.incomingAudio = new Audio('https://distribution-assets.s3.eu-west-2.amazonaws.com/static/media/incoming.mp3');
    this.outgoingAudio = new Audio('https://distribution-assets.s3.eu-west-2.amazonaws.com/static/media/outgoing.mp3');
    this.prevViewportmetaContent = null;
    this.session = new Session();
    this.requestHash = null;

    const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
    const clientId = scriptTag.getAttribute('data-client-id');

    const dataModel = scriptTag.getAttribute('data-model');
    let dataKey = scriptTag.getAttribute('data-key');

    // prioritise the new data-model attribute
    if (dataModel) {
      dataKey = dataModel + '.csv';
    }

    const branding = scriptTag.getAttribute('data-branding');
    let showMainPopup = scriptTag.getAttribute('data-show-main-popup') ? true : false;
    const forceOpenWidget = scriptTag.getAttribute('data-chatbot-mode') ? true : false;

    let chatHistory = this.session.getChatHistory();
    let widgetOpen = this.session.getWidgetStatus();
    let pendingQuery = this.session.getPendingQuery();

    if (widgetOpen || pendingQuery) {
      showMainPopup = false;
    }

    // bring attention to the widget by playing a sound on first user interaction
    // const playAlert = () => {
    //   this.incomingAudio.play();
    //   this.setLastTimeAlertSoundPlayed(moment().unix());
    //   document.removeEventListener('click', playAlert);
    //   document.removeEventListener('scroll', playAlert);
    // }

    // let lastTimeAlertPlayed = this.getLastTimeAlertSoundPlayed();

    // if (!lastTimeAlertPlayed || ((moment().unix() - lastTimeAlertPlayed.unix()) > 10800)) {
    //   document.addEventListener('click', playAlert);
    //   document.addEventListener('scroll', playAlert);
    // }

    this.state = {
      chatHistory,
      dataKey: dataKey,
      clientId: clientId,
      widgetOpen: forceOpenWidget ? true : widgetOpen,
      sendingQuery: false,
      branding,
      queryInputHeight: INITIAL_QUERY_INPUT_HEIGHT,
      queryText: "",
      notificationCount: this.session.getNotificationCount(),
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      showMainPopup: chatHistory.length == 0 ? (forceOpenWidget ? false : showMainPopup) : false,
      pills: [],
      mouseLeftDoc: false
    };
  }

  // attach resize listeners to the context
  // so we can rerender mobile responsive version
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    document.addEventListener('mouseleave', this.onMouseLeaveDoc);

    // fetch and store client pills from upstream
    pillFactory.fetchClientPills().then(data => {
      this.setState({
        pills: pillFactory.formatPills(data.pills)
      })
    });

    // append an initial message if it exists in the script tag
    const { chatHistory } = this.state;
    const initialMessage = this.getInitialMessage();

    if (chatHistory.length == 0 && initialMessage) {
      this.setState({
        chatHistory: [{
          role: 'assistant',
          content: initialMessage
        }]
      });
    }

    let pendingQuery = this.session.getPendingQuery();

    // if we have a pending query then fire it
    if (pendingQuery) {
      this.sendChatQuery(pendingQuery);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    document.removeEventListener('mouseleave', this.onMouseLeaveDoc);
  }

  // when mouse leaves the doc we force open widget
  // and show a don't leave message
  onMouseLeaveDoc = () => {
    const { chatHistory, widgetOpen, mouseLeftDoc } = this.state;
    const loadCount = getLoadCount();
    const lastLeavePopupShownTime = getLeavePopupShownTime() ?? 0;
    const curTime = Math.floor(Date.now() / 1000);
    const canShowWidget = (loadCount % 2) == 0 && loadCount > 0 && ((curTime - lastLeavePopupShownTime) >= 86400);

    if (chatHistory.length < 2 && !widgetOpen && !mouseLeftDoc && canShowWidget && LEAVE_MESSAGE) {
      let newChatHistory = [{
        role: 'assistant',
        content: LEAVE_MESSAGE
      }];

      this.setWidgetOpen(true);
      this.setState({ mouseLeftDoc: true, chatHistory: newChatHistory });
      setLeavePopupShownTime();
    }
  }

  // trigger a rerender with state update
  handleWindowResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: document.getElementById(SYNAPZAI_ADAPTER_ID).clientHeight
    });
  }

  setLastTimeAlertSoundPlayed(time) {
    localStorage.setItem(LAST_TIME_ALERT_SOUND_PLAYED, time)
  }

  getLastTimeAlertSoundPlayed() {
    let lastTimeSoundPlayed = localStorage.getItem(LAST_TIME_ALERT_SOUND_PLAYED);

    if (lastTimeSoundPlayed) {
      return moment.unix(lastTimeSoundPlayed);
    }

    return null;
  }

  /**
   * gets the original user query made
   */
  getOriginalQuery() {
    var { chatHistory } = this.state;

    if (chatHistory.length > 0) {
      return chatHistory[0].content;
    }

    return null;
  }

  /**
  * gets the first entities returned
  */
  getOriginalEntities() {
    var { chatHistory } = this.state;

    for (let entry of chatHistory) {
      if (entry.role == ROLE_ENTITIES) {
        return entry.content;
      }
    }

    return null;
  }

  /**
   * gets the previous query sent by the user
   */
  getPrevQuery() {
    var { chatHistory } = this.state;

    if (chatHistory.length > 1) {
      var countdown = chatHistory.length - 2;

      while (countdown >= 0) {
        let entry = chatHistory[countdown];

        if (entry.role == ROLE_USER) {
          return entry.content;
        }

        countdown--;
      }
    }

    return null;
  }

  /**
   * gets the previous product query sent by the user
   */
  getPrevProductQuery() {
    var { chatHistory } = this.state;

    if (chatHistory.length > 1) {
      var countdown = chatHistory.length - 1;

      while (countdown >= 0) {
        let entry = chatHistory[countdown];

        if (entry.role == ROLE_USER && entry.isProductQuery) {
          return entry.content;
        }

        countdown--;
      }
    }

    return null;
  }

  /**
   * gets the previous set of entities
   */
  getPrevEntities() {
    var { chatHistory } = this.state;

    if (chatHistory.length > 0) {
      var countdown = chatHistory.length - 1;

      while (countdown >= 0) {
        let entry = chatHistory[countdown];

        if (entry.role == ROLE_ENTITIES && !entry.isAdvice) {
          return entry.content;
        }

        countdown--;
      }
    }

    return null;
  }

  /**
   * Gets initial message to insert into the chat if one exists on the invoker
   */
  getInitialMessage() {
    const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
    const initialMessage = scriptTag.getAttribute('data-initial-message');
    return initialMessage;
  }

  /**
  * Resets the chat log to its initial state
  */
  resetChat() {
    this.requestHash = uuidv4();
    const initialMessage = this.getInitialMessage();
    var chatHistory = [];

    if (initialMessage) {
      chatHistory.push({
        role: 'assistant',
        content: initialMessage
      });
    }

    this.setState({
      notificationCount: 0,
      chatHistory,
      sendingQuery: false,
      widgetOpen: true,
    }, () => {
      // make sure to reset the session too
      this.session.flushSession();
    });
  }

  /**
   * Fires a query to the chat api
   * @param {String} query to send to the chat api
   */
  sendChatQuery = (query) => {
    if (this.state.sendingQuery || query.trim() == "") {
        return;
    }
    
    var { chatHistory } = this.state;

    // play outgoing message sound only if its the second message the user has sent
    if (chatHistory.length > 0) {
      this.outgoingAudio.play();
    }

    // temp store for query
    this.session.setPendingQuery(query);

    this.setState({ sendingQuery: true }, () => {
      // make copies for immutability
      var chatHistoryCopy = [].concat(chatHistory);
      chatHistoryCopy = chatHistoryCopy.filter(ch => ch.role != ROLE_RESET);

      // push query to chat history
      chatHistoryCopy.push({
        content: query,
        role: ROLE_USER
      });

      this.setState({
        chatHistory: chatHistoryCopy
      }, () => {
        this.executeRequest(query);
      });
    });
  }

  /**
   * cleans the chat history so it can be processed on the server without errors
   */
  cleanChatHistoryForServer() {
    var { chatHistory } = this.state;

    // dont send the initial message to the server
    if (this.getInitialMessage()) {
      chatHistory = chatHistory.slice(1);
    }

    // filter out entity messages for the server
    return [].concat(chatHistory)
             .filter(entry => entry.role != ROLE_ENTITIES)
             .map(entry => ({ role: entry.role, content: entry.content }))
  }

  /**
   * executes the upstream query, called from @sendChatQuery
   * @param {String} query 
   */
  executeRequest = (query) => {
    const { chatHistory, clientId, dataKey } = this.state;
    var chatHistoryCopy = [].concat(chatHistory);

    // we send the query along with the chat history to server
    // only send the raw version of the chat history upstream
    let chatRequestObject = {
      chatHistory: this.cleanChatHistoryForServer(),
      query,
      clientId,
      dataKey,
      prevQuery: this.getPrevQuery(),
      prevEntities: this.getPrevEntities(),
      originalQuery: chatHistory.length > 1 ? this.getOriginalQuery() : null,
      prevProductQuery: this.getPrevProductQuery()
    };

    // we create a request hash which we compare against to check if the
    // request was cancelled
    let currentRequestHash = uuidv4();
    this.requestHash = currentRequestHash;

    sendChatQueryRequest(chatRequestObject).then(response => {
      this.serverErrorCounter = 0;

      // prevent the response from being processed if the
      // request has been cancelled
      if (this.requestHash != currentRequestHash) {
        return;
      }

      // if the server states that our previous query was a product query
      // then set that for the latest user query
      if (response.is_product_query) {
        chatHistoryCopy[chatHistoryCopy.length - 1].isProductQuery = true;
      }

      chatHistoryCopy.push({
        content: response.result.message,
        role: ROLE_ASSISTANT
      });

      // for showing products if we have matching entities
      if (response.result.products && response.result.products.length > 0) {
        chatHistoryCopy.push({
          content: response.result.products,
          role: ROLE_ENTITIES,
          isAdvice: !!response.is_product_advice
        });
      }

      // update the notification count if the widget is closed
      const { notificationCount, widgetOpen } = this.state;
      let newNotificationCount = 0;

      if (!widgetOpen) {
        newNotificationCount = notificationCount + 1;
      }

      // update session
      this.session.updateChatHistory(chatHistoryCopy);
      this.session.updateNotificationCount(newNotificationCount);
      this.session.setPendingQuery(null);
      this.incomingAudio.play();

      this.setState({
        chatHistory: chatHistoryCopy,
        sendingQuery: false,
        notificationCount: newNotificationCount
      });
    }).catch(error => {
      console.log('Error: ', error);

      // retry request up to 5 times
      // after 5 times show timeout message
      if (this.serverErrorCounter > 5) {
        this.serverErrorCounter = 0;
        let message = "Server timed out, please try again";

        chatHistoryCopy.push({
          content: message,
          role: ROLE_ASSISTANT
        });

        // update session
        this.session.updateChatHistory(chatHistoryCopy);
        this.session.setPendingQuery(null);

        this.setState({
          chatHistory: chatHistoryCopy
        });
      } else {
        // retry
        this.serverErrorCounter++;
        this.executeRequest(query);
      }
    }).finally(() => {
      if (this.serverErrorCounter == 0) {
        this.setState({
          sendingQuery: false,
        });
      }
    });
  }

  // Update chat history
  setChatHistory = (chatHistory) => {
    this.setState({ chatHistory });
  }

  // Updates query input
  setQueryText = (queryText) => {
    if (queryText.trim().length == 0) {
      this.setState({ queryInputHeight: INITIAL_QUERY_INPUT_HEIGHT })
    }

    this.setState({ queryText });
  }

  // Updates query input height so that chatlog can respond to its size
  setQueryInputHeight = (queryInputHeight) => {
    this.setState({ queryInputHeight });
  }

  closePopup = () => {
    this.setState({ showMainPopup: false });
  }

  // sets if the widget should open or not
  setWidgetOpen = (openWidget) => {
    const { notificationCount, widgetOpen } = this.state;
    let newNotificationCount = notificationCount;

    // if we are opening the widget then warm up the backend
    if (openWidget) {
      warmUpBackend();

      // once widget has opened disable main popup
      this.setState({ showMainPopup: false });

    /**
     * when we open the widget we want to disable zoom on iOS devices
     * so that it doesnt zoom in undesirably on the message input
     */
      if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) && (!widgetOpen)) {
        var viewportmeta = document.querySelector('meta[name="viewport"]');

        if (viewportmeta) {
          this.prevViewportmetaContent = viewportmeta.getAttribute('content');
          // zoom out to initial scale
          viewportmeta.setAttribute('content', 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0');
        }
      }

      // we also clear the notification count if the user opens the widget
      newNotificationCount = 0;
    } else {
      if (widgetOpen) {
        // reset zoom capabilities on widget close
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) && (!widgetOpen)) {
          var viewportmeta = document.querySelector('meta[name="viewport"]');
          if (viewportmeta && this.prevViewportmetaContent) {
            viewportmeta.setAttribute('content', this.prevViewportmetaContent);
          }
        }
      }
    }

    // update session
    this.session.toggleWidgetStatus(openWidget);
    this.session.updateNotificationCount(newNotificationCount);

    this.setState({ 
      widgetOpen: openWidget,
      notificationCount: newNotificationCount
    })
  }

  render() {
    const { children } = this.props
    const { 
      chatHistory, 
      dataKey, 
      clientId, 
      widgetOpen, 
      branding,
      queryInputHeight,
      queryText,
      sendingQuery,
      notificationCount,
      windowWidth,
      windowHeight,
      showMainPopup,
      pills,
    } = this.state;

    const { 
      setChatHistory, 
      setWidgetOpen, 
      sendChatQuery, 
      setQueryInputHeight,
      setQueryText,
      getPrevEntities,
      resetChat,
      closePopup,
      getInitialMessage,
    } = this

    return (
      <MainContext.Provider
        value={{
          chatHistory,
          dataKey,
          clientId,
          widgetOpen,
          setChatHistory,
          setWidgetOpen,
          sendChatQuery,
          branding,
          queryInputHeight,
          setQueryInputHeight,
          queryText,
          setQueryText,
          sendingQuery,
          notificationCount,
          getPrevEntities: getPrevEntities.bind(this),
          windowWidth,
          windowHeight,
          showMainPopup,
          resetChat: resetChat.bind(this),
          pills,
          closePopup,
          getInitialMessage: getInitialMessage.bind(this),
        }}
      >
        {children}
      </MainContext.Provider>
    )
  }
}

export default MainContextProvider

export { MainContextProvider, MainContext }