import { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import searchIcon from '../../assets/search_ai.png';
import Spinner from "../Misc/Spinner";
import NotificationBadge from "../Misc/NotificationBadge";
import { INVOKE_SCRIPT_ID } from "../../constants";

function WidgetCTA(props) {
  const { 
    setWidgetOpen, 
    widgetOpen, 
    sendingQuery,
    sendChatQuery,
    setQueryText,
    notificationCount,
    chatHistory,
  } = useContext(MainContext);

  // if the widget is open then clicking the widget cta executes a send request
  // else it opens the widget
  const onWidgetClick = () => {
    let editor = document.querySelector('.synapzai-query-input-editor');
    let editorText = '';
    
    if (editor) {
      editorText = editor.innerText || editor.textContent;
    }

    if (widgetOpen) {
      if (editorText.trim().length == 0) {
        return setWidgetOpen(false);
      }

      sendChatQuery(editorText);
      setQueryText('');
    } else {
      setWidgetOpen(true);
      setTimeout(() => editor && editor.focus());
    }
  }

  var classNames = 'synapzai-widget-cta ';
  var hasChathistory = chatHistory.length > 1;
  const scriptTag = document.getElementById(INVOKE_SCRIPT_ID);
  const chatbotMode = scriptTag.getAttribute('data-chatbot-mode') ? true : false;

  if (sendingQuery) {
    classNames += ' synapzai-widget-cta-disabled';
  }

  if (widgetOpen && !chatbotMode) {
    classNames += ' synapzeai-bounce-effect';
  }

  if (hasChathistory) {
    classNames += ' synapzai-widget-cta-chat-history';
  }

  if (props.chatOnlyMode) {
    classNames += ' synapzai-widget-chat-only';
  }

  if (widgetOpen) {
    classNames += ' cta-widget-open';
  }

  return (
    <div
      onClick={onWidgetClick} 
      className={classNames}
    >
      {sendingQuery ?
        <Spinner />
      :
        <img className={widgetOpen ? "cta-widget-open" : ""} src={searchIcon} />
      }
      {
        notificationCount > 0 &&
        <NotificationBadge count={notificationCount} />
      }
    </div>
  );
}

export default WidgetCTA;