import addToBasketIcon from '../../assets/add_to_basket.png';
import { DEFAULT_CURRENCY_SYMBOL, PRODUCT_CTA_TEXT, SHOW_ADD_TO_CART_BUTTON } from '../../constants';
import { prefixWithLocaleCurrency } from '../../utils/string';

function ProductItem(props) {
  const { product } = props;

  const openProduct = () => {
    const url = new URL(product.url);
    url.searchParams.set('synmio', 'true');
    window.open(url.toString(), '_blank').focus();
  }

  const hasDiscount = product.discounted_price ?? null;

  return (
    <div onClick={openProduct} className="synapzai-product-item">
      <img className="synapzai-product-item-img" src={product.image} />
      {props.topMatch &&
        <div className="synapzai-product-item-top-match">
          <span>TOP MATCH</span>
        </div>
      }
      <p>{product.name}</p>
      <div className="synapzai-product-item-footer">
        <div className='synapzai-product-item-footer-price'>
          <span className={hasDiscount ? 'synapzai-product-item-strikethrough' : ''}>
            {prefixWithLocaleCurrency(product.price, DEFAULT_CURRENCY_SYMBOL)}
          </span>
          {hasDiscount && 
            <span className='synapzai-product-item-discount'>
              {prefixWithLocaleCurrency(product.discounted_price, DEFAULT_CURRENCY_SYMBOL)}
            </span>
          }
        </div>
        {SHOW_ADD_TO_CART_BUTTON &&
          <img src={addToBasketIcon} />
        }
        <div className='synapzai-product-item-footer-shop-now'>
          <span>{PRODUCT_CTA_TEXT}</span>
        </div>
      </div>
    </div>
  );
}
  
export default ProductItem;