
// gets all children and nested children of element
export function getAllChildren(element) {
    let allChildren = [];

    function traverse(node) {
        if (node.nodeType === 1) { // Check if it's an element node
            allChildren.push(node);

            // Recursively traverse child nodes
            for (let i = 0; i < node.childNodes.length; i++) {
                traverse(node.childNodes[i]);
            }
        }
    }

    traverse(element);
    return allChildren;
}

// gets either white or black depending on luminance
export function getContrastColor(hexColor) {
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate relative luminance
  const luminance = 0.2126 * Math.pow(r / 255, 2.2) +
                    0.7152 * Math.pow(g / 255, 2.2) +
                    0.0722 * Math.pow(b / 255, 2.2);

  // Decide on contrast color
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
}